import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import RawDisplay from './RawDisplay';

const useStyles = makeStyles({
  asset: {
    maxWidth: '100%'
  },
  progress: {
    margin: '3em'
  },
});

function MobileAssetDisplay({ asset }) {
  const [loaded, setLoaded] = React.useState(asset.type === 'image' ? false : true);

  React.useEffect(() => {
    if (asset.type === 'image') {
      const downloadedImage = new Image();

      downloadedImage.onload = () => {
        asset.cache = downloadedImage;
        setLoaded(true);
      };

      downloadedImage.src = asset.url;
    }
  }, [asset]);

  const classes = useStyles();

  return (
    <>
      {loaded ? (
        <RawDisplay
          asset={asset}
          ComponentProps={{
            className: classes.asset,
            loop: true
          }}
        />
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  );
}

export default MobileAssetDisplay;
