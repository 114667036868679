import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { references } from '../data/references';
import Section from '../utils/Section';
import ReferenceSection from './ReferenceSection';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  }
}));

function References() {
  const classes = useStyles();

  return (
    <>
      <Section background wide>
        <Grid container justify="flex-end">
          <Grid item lg={6}>
            <Typography className={classes.paragraph} variant="body1" align="center" component="h1">
              We have helped our customers to streamline their engineering processes by developing new applications and enhancing existing
              tools via software integrations or using mathematical optimization.
            </Typography>

            <Typography className={classes.paragraph} variant="body1" align="center" component="h1">
              As professionals in structural engineering, we are supporting our customers with their product R&amp;D. We are also providing
              technical support for both existing and new tools.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {references.map((reference, index) => {
        return (
          <ReferenceSection
            key={index}
            reference={reference}
            index={index}
          />
        );
      })}
    </>
  );
}

export default References;
