import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Hidden, Link, withStyles } from '@material-ui/core';
import { ReactComponent as SloganLogo } from '../assets/sorvimo-slogan1.svg';

const styles = (theme) => {
  return {
    logo: {
      height: theme.spacing(5),
      width: 'auto'
    },
    horizontalSpacer: {
      flexGrow: 1
    }
  }
};

const FOOTER_LINKS = [
  {
    text: 'Home',
    path: '/',
  },
  {
    text: 'Sorvimo tools',
    path: '/tools',
  },
  {
    text: 'References',
    path: '/references',
  },
  {
    text: 'Team',
    path: '/team',
  },
  {
    text: 'Company',
    path: '/company',
  },
]

function FooterLinks({ classes }) {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Hidden xsDown>
          <SloganLogo className={classes.logo} />
        </Hidden>
      </Grid>

      <Hidden mdDown>
        <Grid className={classes.horizontalSpacer} item />

        {FOOTER_LINKS.map((link, index) => {
          return (
            <Grid key={index} item>
              <Link
                component={RouterLink}
                to={link.path}
                color="inherit"
                variant="subtitle1"
              >
                {link.text}
              </Link>
            </Grid>
          );
        })}
      </Hidden>
    </Grid>
  );
}

export default withStyles(styles)(FooterLinks);
