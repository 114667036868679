import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      width: theme.spacing(25)
    },
    buttonLinkTag: {
      textDecoration: 'none',
    },
    linkButton: {
      marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      background: '#fff',
      boxShadow: '0 0 5px #ccc',
      borderRadius: 0
    },
    contentText: {
      textTransform: 'none'
    }
  }
});

function Contact() {
  const classes = useStyles();

  return (
    <Section>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid className={classes.divider} item>
          <Divider direction="horizontal" />
        </Grid>

        <Grid item>
          <Typography variant="h5" align="center">Contact</Typography>
        </Grid>

        <Grid item lg={6} align="center">
          <Typography className={classes.contentText} variant="h4">Want to know more about us?</Typography>
        </Grid>

        <Grid item>
          <RouterLink to="/team" className={classes.buttonLinkTag}>
            <Button className={classes.linkButton}>
              See our team
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
    </Section>
  );
}

export default Contact;
