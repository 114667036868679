import React from 'react';
import { useSsrLocalStorage } from './ssrLocalStorage';

export function useToolsAnalytics() {
  const [status, setStatus] = React.useState('init');

  const [analyticsUserId, setAnalyticsUserId] = useSsrLocalStorage('analyticsId', '');
  const [analyticsTermId, setAnalyticsTermId] = useSsrLocalStorage('analyticsTermId', '');

  const setIds = (userId, termId) => {
    setAnalyticsUserId(userId);
    setAnalyticsTermId(termId);
    setStatus('ready');
  };

  const checkUuidValidity = (uuid) => {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
    return re.test(uuid);
  };

  const createNewTerm = async () => {
    const response = await fetch('https://sorvimo-learning.herokuapp.com/apps/analytics/terms/create/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: checkUuidValidity(analyticsUserId) ? analyticsUserId : null,
      }),
    });

    if (!response.ok) {
      console.warn('Failed to create new analytics term', response);
      setStatus('ready');
      return;
    }

    const data = await response.json();

    if (data.flag === 0) {
      console.warn('Failed to create new analytics term', data);
      setStatus('ready');
      return;
    }

    setIds(data.term.user, data.term.id);
  };

  const checkTermValidity = (term) => {
    const date = new Date();
    const termDate = new Date(term.created);

    if (Math.abs(date.getFullYear() - termDate.getFullYear()) > 0 ||
        Math.abs(date.getMonth() - termDate.getMonth()) > 0 ||
        Math.abs(date.getDate() - termDate.getDate()) > 0) {
      return false;
    }

    return true;
  }

  const checkTermData = async () => {
    const response = await fetch('https://sorvimo-learning.herokuapp.com/apps/analytics/terms/', {
      method: 'GET',
    });

    if (!response.ok) {
      console.warn('Failed to query analytics terms', response);

      await createNewTerm();
      return;
    }

    const terms = await response.json();
    const term = terms.find((term) => term.id === analyticsTermId);

    if (!term || term.user !== analyticsUserId || !checkTermValidity(term)) {
      await createNewTerm();
      return;
    }

    setIds(term.user, term.id);
  };

  if (typeof window !== 'undefined' && status === 'init') {
    setStatus('loading');

    if (analyticsUserId === '' || analyticsTermId === '') {
      createNewTerm();
    } else {
      checkTermData();
    }
  }

  return {
    userId: analyticsUserId,
    termId: analyticsTermId,
    status: status,
  };
}
