import React from 'react';
import HyphenatedParagraph from '../utils/HyphenatedParagraph';
import Section from '../utils/Section';
import { makeStyles, Typography } from '@material-ui/core';
import ContactCard from '../contact/ContactCard';

const useStyles = makeStyles((theme) => {
  return {
    mainHeader: {
      marginBottom: theme.spacing(3),
      fontSize: 18,
    },
    header: {
      marginTop: theme.spacing(3),
      fontSize: 15,
      textAlign: 'left',
      marginLeft: theme.spacing(1),
    },
    divider: {
      width: theme.spacing(25),
      margin: 'auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  };
});

function HistoryPage() {
  const samiContact = {
    name: 'Sami Pajunen',
    title: 'Hallituksen puheenjohtaja',
    imageUrl: '/images/contacts/sami_pajunen.png',
  };

  const classes = useStyles();

  return (
    <>
      <Section background wide>
        <Typography className={classes.mainHeader} variant="h6" align="center">
          Startup-yrityksenä aloittanut Sorvimo Optimointipalvelut Oy täyttää viisi vuotta (7.9.2021)
        </Typography>

        <Typography className={classes.header} variant="h6" align="center">
          Alkusysäys tutkimushankkeesta
        </Typography>

        <HyphenatedParagraph lang="fi">
          Sorvimon alkuperäisten yritysideoiden juuret ovat silloisen Tampereen teknillisen yliopiston tutkimuksessa ja Tekesin TutLi-hankkeessa
          (TutLi = Tutkimuksesta uutta liiketoimintaa), jossa tutkittiin rakenteiden optimointiosaamisen kaupallistamista. Alusta asti Sorvimon
          liikeidea on pohjautunut tavalla tai toisella teknisen suunnittelun automatisointiin ja tehostamiseen, ja yrityksen osaaminen on rakentunut
          mekaniikan, rakennesuunnittelun sekä ohjelmoinnin ympärille. Vaikka yrityksen keskeinen tavoite suunnittelun automatisoinnista ei ole
          muuttunut, Sorvimon ensimmäisistä bisnesideoista yksikään ei ole jäänyt elämään alkuperäisessä muodossaan. Yrityksen perustamisen aikoihin
          tehdyissä liiketoimintasuunnitelmissa kaavailtiin mm. optimointiapplikaatioita isojen analyysi- ja suunnittelusoftien rajapintoihin sekä
          omana tuotteena myytäviä rakennesuunnittelusoftia, joissa olisi integroituna modernit optimointityökalut.
        </HyphenatedParagraph>

        <Typography className={classes.header} variant="h6" align="center">
          Kuolemanlaakso ja sen ylitys
        </Typography>

        <HyphenatedParagraph lang="fi">
          Alkuun teimme alkuperäisen idean mukaisen ANSYS-ohjelmaan sopivan optimointisovelluksen, joka oli globaalissa myynnissä ANSYS App Storessa
          muutaman vuoden. Samoin kehitimme pari suunnitteluautomaattia, mutta kumpikin näistä liiketoiminnoista osoittautui olevan liiketaloudellisesti
          kestämättömällä pohjalla. Törmäsimme hyvin pian akateemisen maailman ja yritysmaailman rajapintaan, jossa kävi selväksi, että
          yliopistotutkimuksessa kehitetyt hienot ja globaalistikin edistykselliset ideat eivät sellaisenaan mene kaupaksi teollisuusyrityksille. Näin kävi
          siitä huolimatta, että selkeää tarvetta tällaisille työkaluille oli indikoitu.
        </HyphenatedParagraph>

        <HyphenatedParagraph lang="fi">
          Sorvimo oli siis pian perustamisensa jälkeen usealle aloittavalle startup-yritykselle tutussa kuolemanlaaksovaiheessa, jonka aikana käytiin
          kilpajuoksua siitä, riittävätkö yrityksen kassavarat uuden liiketoimintaidean kehittämiseen, vai loppuuko yrityksen taival alkuunsa. Tänä
          aikana osakkaiden yhteistyöverkostot käytiin tarkkaan läpi, ja lopulta kovan työn sekä muutaman sopivan yhteensattuman ansiosta Sorvimo sai
          ensimmäisen ison softaprojektinsa. Tämä projekti oli yritykselle se kriittinen näytön paikka, josta riippui yrityksen tulevaisuus. Nyt useita
          vuosia myöhemmin on ilo todeta, että projektin kunnialla maaliin vieneet Sorvimon työntekijät tekivät hyvää työtä ja kyseinen asiakas on
          edelleen Sorvimon tärkeimpien asiakkaiden joukossa.
        </HyphenatedParagraph>

        <Typography className={classes.header} variant="h6" align="center">
          Toiminta vakiintuu asiakkaille räätälöityihin tuotteisiin 
        </Typography>

        <HyphenatedParagraph lang="fi">
          Kun Sorvimoa perustettiin, uskoimme, että menestyksekkään yritystoiminnan tärkein asia on tarjota tuotetta tai palvelua, jolle on kentällä
          kysyntää. Sorvimon kilpailueduksi tunnistettiin korkean teoreettisen osaamisen lisäksi rakenteiden mitoittamisen ja ohjelmointiosaamisen
          yhdistäminen. Hyvin sujuneet alkutaipaleen ohjelmistoprojektit vahvistivat käsitystä siitä, että Sorvimo on oikealla tiellä ja yrityksen
          tulevaisuus löytyisi juuri vaativien rakennesuunnittelun web-softien tuottamisessa. Yrityksen toimintaa alettiin kehittämään systemaattisesti
          tähän suuntaan.
        </HyphenatedParagraph>

        <HyphenatedParagraph lang="fi">
          Koko olemassaolonsa ajan Sorvimo on ydintoimintansa kehittämisen lisäksi etsinyt aktiivisesti myös uusia liiketoimintamahdollisuuksia mm.
          omien laskentasoftien, laskentapalveluiden sekä digitaalisen koulutuksen aloilta. Yrityksen alkuvuosina on tehty markkinatutkimuksia eri
          palveluille ja tuotteille sekä Suomessa että useissa Euroopan maissa. Joitain selkeästi houkuttelevia liiketoimintamahdollisuuksia näissä
          hankkeissa on löytynyt, mutta nämä suunnitelmat menivät kuitenkin määrittelemättömän pituiselle tauolle koronapandemian iskiessä
          maailmantalouteen. Poikkeusaikojen epävarmuus laittoi Sorvimonkin miettimään omaa strategiaansa uusiksi, ja valmistautumaan nopeisiinkin
          markkinoiden muutoksiin. Tällä hetkellä näyttäisi kuitenkin siltä, että pandemia ei lopulta tuonut Sorvimon ydinbisnekseen ainakaan niitä
          isoimmin pelättyjä muutoksia, vaan maailman digitalisoitumisen nopeuduttua pakon edessä Sorvimon tarjoamalle osaamiselle näyttäisi olevan
          jopa entistä suurempaa kysyntää.
        </HyphenatedParagraph>

        <Typography className={classes.header} variant="h6" align="center">
          Tärkeintä on asiakkaan etu eksponentiaalisen kasvun sijaan
        </Typography>

        <HyphenatedParagraph lang="fi">
          Nykyhetkestä katsottuna yrityksen ensimmäiset viisi vuotta ovat olleet menestyksellisiä ja toisaalta ennustettavia. Nimittäin suurin osa
          startup-yrityksistä muuttaa nopeastikin toimintaansa alkuperäiseen liikeideaan nähden. Näin kävi Sorvimollekin. Ehkä omaleimaisinta Sorvimon
          toimintatavoissa on kuitenkin ollut tietty harkittu vanhanaikaisuus. Sorvimo ei ole koskaan pyrkinyt olemaan yhdenkään hype-aallon harjalla.
          Sorvimo ei ole ollut aktiivisin yritys Slushin tai Kasvu Openin kaltaisissa foorumeissa, emmekä ole somessakaan pöhisseet niin paljon kuin ehkä
          olisi voinut.
        </HyphenatedParagraph>

        <HyphenatedParagraph lang="fi">
          Vanhanaikaisesti voisi todeta, että Sorvimo on suunnitelmallisesti keskittynyt puheiden sijasta tekoihin, ja pyrkinyt palvelemaan nykyisiä
          asiakkaitaan parhaalla mahdollisella tavalla. Ehkä tämän valitun strategian vuoksi Sorvimon kasvu ei ole ollut TutLi-hankkeessa suunnitellun
          mukaisesti eksponentiaalista, vaan paremminkin lineaarista. Toisaalta Sorvimo on edelleen olemassa, eikä ole kaatunut monen muun
          startup-yrityksen tavoin, kun pinnalla oleva hype on vaihtunut toiseen. Lisäksi viidessä vuodessa lineaarinenkin kasvu on johtanut siihen, että
          tällä hetkellä yrityksen henkilöstömäärä on aloitukseen verrattuna kolminkertaistunut ja liikevaihto aivan eri luokkaa kuin ensimmäisinä
          tilikausina. Parilla avainluvulla esitettynä: Sorvimon yhteenlaskettu liikevaihto koko olemassaolonsa ajalta ylittää 1,5 Meur, ja yrityksessä
          on tällä hetkellä viisi täysipäiväistä sekä kolme osa-aikaista työntekijää. 
        </HyphenatedParagraph>

        <Typography className={classes.header} variant="h6" align="center">
          Osaamiselle on kysyntää – yrityksen tulevaisuus näyttää valoisalta
        </Typography>

        <HyphenatedParagraph lang="fi">
          Toimintaympäristömme digitalisoituu väistämättä, ja osaamisellemme on kasvavaa kysyntää. Tärkein voimavaramme on osaava ja motivoitunut
          henkilöstö, jolle yritys pystyy tarjoamaan mielenkiintoisia ja alan kehitykseen vaikuttavia projekteja. Henkilöstön monipuolinen osaaminen
          on kilpailutekijä, josta olemme ylpeitä. Valitulla kasvustrategialla pidämme rekrytoinnin jatkossakin auki ja täydennämme henkilöstöämme
          mahdollisuuksien mukaan, kun potentiaalisen uuden työntekijän ja yrityksen intressit, arvot ja osaaminen kohtaavat.
        </HyphenatedParagraph>

        <HyphenatedParagraph lang="fi">
          Tulevaisuudessa yrityksen kasvaessa sen johtaminen nousee entistä tärkeämpään asemaan. Kun aloittelevan startup-Sorvimon hallitustyöskentely
          muistutti lähinnä lennokasta ideointipalaveria, niin yrityksen koon ja vastuiden kasvaessa tässäkin asiassa on siirrytty formaalimpaan
          työskentelyyn sekä uudistettu hallituksen kokoonpanoa kovan luokan ulkopuolisella asiantuntijalla. Startup-yrityksillä on aina ollut maine
          ketterinä ja joustavina työpaikkoina, joissa on tilaa uusille toimintatavoille ja ideoille. Tästä innostavasta ja avoimesta
          työskentelykulttuurista emme aio jatkossakaan luopua Sorvimon kasvaessa ja kehittyessä.
        </HyphenatedParagraph>
      </Section>

      <Section reverse>
        <ContactCard
          contact={samiContact}
          index={0}
          standalone
        />
      </Section>
    </>
  );
}

export default HistoryPage;
