import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import MobileAssetDisplay from './MobileAssetDisplay';

const useStyles = makeStyles((theme) => {
  return {
    assetRoot: {
      width: '100%',
      height: '100%'
    },
    dot: {
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.dark,
      width: 5,
      height: 5,
      margin: 3
    },
    selectedDot: {
      backgroundColor: theme.palette.secondary.dark
    },
  };
});

function MobileReferenceCarousel({ assets }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();

  return (
    <>
      <SwipeableViews
        enableMouseEvents
        onChangeIndex={setSelectedIndex}
      >
        {assets.map((asset, index) => {
          return (
            <Grid
              className={classes.assetRoot}
              key={index}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <MobileAssetDisplay asset={asset} />
              </Grid>
            </Grid>
          )
        })}
      </SwipeableViews>
      <Grid container justify="center">
        {assets.map((_, index) => {
          return (
            <Grid
              className={classNames(classes.dot, {
                [classes.selectedDot]: index === selectedIndex
              })}
              key={index}
            />
          );
        })}
      </Grid>
    </>
  );
}

export default MobileReferenceCarousel;
