import React from 'react';
import CompanyInformation from './CompanyInformation';
import ContactInformationSection from './ContactInformationSection';

function Company() {
  return (
    <>
      <CompanyInformation />
      <ContactInformationSection />
    </>
  );
}

export default Company;
