import { Grid, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    wideScreenHorizontalSpacer: {
      flexGrow: 1
    },
    pageLink: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75)
    },
    activePageLink: {
      fontWeight: 'bold'
    }
  }
});

function WideScreenBar() {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.wideScreenHorizontalSpacer} />
      <Grid item>
        <Link
          component={RouterNavLink}
          to="/"
          color="inherit"
          variant="overline"
          className={classes.pageLink}
          exact
          activeClassName={classes.activePageLink}
        >
          Home
        </Link>
      </Grid>

      <Grid item>
        <Link
          component={RouterNavLink}
          to="/tools"
          color="inherit"
          variant="overline"
          className={classes.pageLink}
          activeClassName={classes.activePageLink}
        >
          Sorvimo tools
        </Link>
      </Grid>

      <Grid item>
        <Link
          component={RouterNavLink}
          to="/references"
          color="inherit"
          variant="overline"
          className={classes.pageLink}
          activeClassName={classes.activePageLink}
        >
          References
        </Link>
      </Grid>

      <Grid item>
        <Link
          component={RouterNavLink}
          to="/team"
          color="inherit"
          variant="overline"
          className={classes.pageLink}
          activeClassName={classes.activePageLink}
        >
          Team
        </Link>
      </Grid>

      <Grid item>
        <Link
          component={RouterNavLink}
          to="/company"
          color="inherit"
          variant="overline"
          className={classes.pageLink}
          activeClassName={classes.activePageLink}
        >
          Company
        </Link>
      </Grid>
    </>
  );
}

export default WideScreenBar;
