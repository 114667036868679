import React from 'react';
import { Grid, Hidden, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as Logo } from '../assets/sorvimo-logo2.svg';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import ContactCard from './ContactCard';

const useStyles = makeStyles((theme) => {
  return {
    sectionTitle: {
      marginBottom: theme.spacing(3)
    }
  };
});

function ContactSection({ contacts, groupName, reverse }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Section wide reverse={reverse}>
      <Grid container spacing={smallScreen ? 0 : 6} wrap="nowrap">
        <Hidden mdDown>
          <Grid item lg={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Logo />
              </Grid>

              <Grid item>
                <Typography variant="h5">
                  {groupName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={1}>
            <Divider direction="vertical" height="100%" />
          </Grid>
        </Hidden>

        <Grid item xs={12}>
          <Hidden lgUp>
            <Typography className={classes.sectionTitle} variant="h5" align="center">{groupName}</Typography>
          </Hidden>
          <Grid container spacing={2}>
            {contacts.map((contact, index) => {
              return (
                <ContactCard
                  key={index}
                  contact={contact}
                  index={index}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default ContactSection;
