import React from 'react';
import { Container, Box, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundRepeat: 'no-repeat',
      paddingTop: ({ extendedClearance, noClearance }) => extendedClearance ? '10em' : noClearance ? null : '5em',
      paddingBottom: ({ extendedClearance, noClearance }) => extendedClearance ? '10em' : noClearance ? null : '5em',
      height: ({ fullHeight }) => fullHeight ? '100%' : null,
    },
    reverseRoot: {
      backgroundColor: theme.palette.secondary.main,
    },
    rootBackground: {
      backgroundImage: ({ backgroundUrl, smallScreen, reverse }) => {
        let value = '';

        if (smallScreen && !reverse && !backgroundUrl) {
          value = 'linear-gradient(#fffc, #fff9), ';
        } else if (!reverse && !backgroundUrl) {
          value = 'linear-gradient(to right, #fff7, #fffe 50%), ';
        }

        value += `url(${backgroundUrl || '/images/sorvimo-visual_element-building-01.png'})`;

        return value;
      },
      backgroundPosition: ({ backgroundUrl, smallScreen }) => backgroundUrl ? `${smallScreen ? '50%' : '0'} 0` : `${smallScreen ? '70%' : '90%'} 10%`,
      backgroundSize: ({ backgroundUrl }) => backgroundUrl ? 'cover' : null,
    },
    rootFillBackground: {
      backgroundImage: ({ backgroundUrl }) => `url(${backgroundUrl || '/images/sorvimo-visual_element-building-01.png'})`,
      backgroundPosition: ({ backgroundUrl }) => backgroundUrl ? null : '50%',
      backgroundSize: ({ backgroundUrl, smallScreen }) => backgroundUrl ? 'cover' : (smallScreen ? 'auto' : '100%'),
    },
    container: {
      height: '100%',
    },
    content: {
      height: '100%',
    },
  };
});

function Section({ 
  reverse=null, extendedClearance=null, noClearance=null, fullHeight=null,
  background=null, backgroundFill=null, extraWide=null, wide=null, narrow=null,
  backgroundUrl=null, children, ...rest
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  let maxWidth = 'md';

  if (narrow)
    maxWidth = 'sm';
  if (wide)
    maxWidth = 'lg';
  if (extraWide)
    maxWidth = 'xl';

  const classes = useStyles({
    extendedClearance, noClearance, fullHeight, backgroundUrl, smallScreen, reverse
  });

  return (
    <Box
      className={classNames(classes.root, {
        [classes.reverseRoot]: reverse,
        [classes.rootBackground]: background && !backgroundFill,
        [classes.rootFillBackground]: backgroundFill,
      })}
      {...rest}
    >
      <Container className={classes.container} maxWidth={maxWidth}>
        <Box className={classes.content}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}

export default Section;
