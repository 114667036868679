import React from 'react';
import { Typography, Grid, Link, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import Section from '../utils/Section';
import ToolCard from './ToolCard';
import ToolGroup from './ToolGroup';
import ExternalToolView from './ExternalToolView';
import { tools } from '../data/tools';
import ContactUsForm from './ContactUsForm';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    padding: theme.spacing(1),
  },
  gapBottom: {
    marginBottom: theme.spacing(2),
  },
  privacyParagraph: {
    fontSize: 'smaller',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  privacyLink: {
    fontSize: 'unset',
  },
  toolCard: {
    width: 300
  },
  toolCardDescription: {
    width: 300,
    flex: '1 1 auto',
  },
  EULogo: {
    display: 'block',
    width: 300,
  },
  contactUsHeader: {
    marginBottom: theme.spacing(2),
  },
  contactParagraph: {
    paddingTop: theme.spacing(1),
  },
}));

function Tools() {
  const [tool, setTool] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const toolID = location.hash.replace('#', '');
  
  // Check that selected ID and current tool data are synced. URL can be used to directly open a tool.
  React.useEffect(() => {
    if (toolID.length > 0 && (!tool || tool.id !== toolID)) {
      const selectedTool = tools.find((tool) => tool.id === toolID);
      setTool(selectedTool ?? null);
    } else if (toolID === '' && tool) {
      setTool(null);
    }
  }, [toolID, tool]);

  const classes = useStyles();
  const FLOOR_DESIGN = tools.find(tool => tool.id === 'floordesign');

  const closeTool = () => {
    history.push(location.pathname);
    setTool(null);
  }

  const openTool = tool => {
    history.push(`${location.pathname}#${tool.id}`);
    setTool(tool);
  }  

  return (
    <>
      <Section reverse>
        <Grid
          container
          direction="column"
          justify="center"
          spacing={1}
        >
          <Grid className={classes.gapBottom} item>
            <Typography className={classes.paragraph} variant="body1">
              Our passion is to create the most efficient and easy-to-use tools for structural engineering.
            </Typography>

            <Typography className={classes.paragraph} variant="body1">
              We are providing Sorvimo Tools to demonstrate how quickly engineering tasks can be solved with modern, intuitive applications.
            </Typography>
          </Grid>

          <Grid
            container
            justify="flex-start"
            spacing={2}
          >
            <Grid className={classes.toolCard} item>
              <ToolCard
                tool={FLOOR_DESIGN}
                onOpen={openTool}
              />
            </Grid>

            <Grid className={classes.toolCardDescription} item>
              <div>
                <Typography className={classes.paragraph} variant="body1">
                  Our project has received funding from the ministry of environment from Low-carbon Built Environment Programme, which is funded by the EU's temporary recovery instrument.
                </Typography>
                <img className={classes.EULogo} src="/images/tools/EU_Next_Generation_EN.png" alt="EU Next Generation" />
              </div>
            </Grid>
          </Grid>

          <ToolGroup openTool={openTool} />

          <Grid item>
            <Typography className={classes.privacyParagraph} variant="body2">
              {`Sorvimo Tools collect anonymous usage data. `}
              <Link
                className={classes.privacyLink}
                component={RouterLink}
                to="/privacy"
                color="inherit"
                variant="body2"
              >
                See privacy policy.
              </Link>
            </Typography>
          </Grid>

        </Grid>

        <ExternalToolView tool={tool} onClose={closeTool} />
      </Section>

      <Section wide>
        <Grid
          container
          justify="space-between"
          wrap="wrap-reverse"
          alignItems="center"
        >
          <Grid item xs={12} md={5}>
            <ContactUsForm />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className={classes.contactUsHeader} variant="h4">Do you have comments or ideas? Contact us!</Typography>
            <Typography className={classes.contactParagraph} variant="body1">
              If you have any feedback on the existing tools or would like to see something new, don't hesitate to contact us.
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Tools;
