import { createMuiTheme } from "@material-ui/core"

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff'
        },
        secondary: {
            main: '#e5e5e5',
            dark: '#333'
        },
        focus: {
            main: '#000'
        },

        text: {
            primary: '#000',
            secondary: '#fff',
            light: '#888',
            disabled: '#ccc'
        },

        action: {
            disabled: '#888',
            disabledBackground: '#ccc'
        }
    },

    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif'
        ].join(','),
        h1: {
            textTransform: 'uppercase',
            fontSize: 68,
            fontWeight: 'bold',
            lineHeight: 1.1
        },
        h3: {
            textTransform: 'uppercase',
            fontSize: 42,
            fontWeight: 'bold'
        },
        h4: {
            textTransform: 'uppercase',
            fontSize: 28,
            fontWeight: 'bold'
        },
        h5: {
            textTransform: 'uppercase',
            fontSize: 24,
            fontWeight: 'bold'
        },
        h6: {
            textTransform: 'uppercase',
            fontSize: 17,
            fontWeight: 'bold'
        },
        subtitle1: {
            textTransform: 'uppercase',
            fontSize: 15,
            fontWeight: 'bold'
        },
        body1:{
            fontSize: 17
        },
        body2: {
            fontSize: 13
        },
        button: {
            textTransform: 'uppercase',
            fontSize: 17,
            fontWeight: 'bold'
        },
        overline: {
            textTransform: 'uppercase',
            fontSize: 13,
            fontWeight: 'lighter',
            letterSpacing: 5
        }
    }
});
