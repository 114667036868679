import { Box, Fade, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import RawDisplay from './RawDisplay';

const styles = {
  title: {
    position: 'absolute',
    bottom: '1em',
    left: '1em',
    right: '1em',
    pointerEvents: 'none',
    color: 'white',
    zIndex: 10,
    textDecoration: 'underline'
  },
  description: {
    position: 'absolute',
    bottom: '3em',
    left: '1em',
    right: '1em',
    pointerEvents: 'none',
    color: 'white',
    zIndex: 10
  },
  gradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background: 'linear-gradient(to bottom, #fff0, #000f)',
    pointerEvents: 'none'
  }
};


function DisplaySelector({ classes, asset, onEnded, selected, ComponentProps={}, ...rest }) {
  const TextBackgroundGradient = () => (<div className={classes.gradient} />);

  const showOverlay = asset.type === 'image' ? selected : false;
  const fadeRest = showOverlay ? { ...rest } : {}

  return (
    <>
      <RawDisplay asset={asset}
                  onEnded={onEnded}
                  ComponentProps={{ ...ComponentProps }}
                  {...rest} />

      <Fade in={showOverlay}
            timeout={500}
            {...fadeRest} >
        <Box>
          <Typography className={classes.title}
                      variant='h6'>
            {asset.title}
          </Typography>

          <Typography className={classes.description}
                      variant='body1'>
            {asset.description}
          </Typography>

          <TextBackgroundGradient />
        </Box>
      </Fade>
    </>
  );
};

export default withStyles(styles)(DisplaySelector);
