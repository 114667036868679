import React from 'react';

const RawDisplay = React.forwardRef(({ asset, onEnded, ComponentProps={}, ...rest }, ref) => {
  if (asset.type === 'image') {
    return (
      <img
        ref={ref}
        src={asset.cache?.url || asset.url}
        alt={asset.title}
        {...ComponentProps}
        {...rest}
        style={{ pointerEvents: 'none' }}
      />
    );
  }

  if (asset.type === 'video') {
    return (
      <video
        ref={ref}
        autoPlay
        muted
        playsInline
        {...ComponentProps}
        {...rest}
      >
        <source src={asset.url}
                type="video/mp4" />
      </video>
    );
  }

  return null;
});

export default RawDisplay;
