import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AssetDisplay from './AssetDisplay';
import LargeAssetModal from './LargeAssetModal';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  carouselAssetItem: {
    flexGrow: 1
  },
  carouselAssetRoot: {
    height: 600,
    width: '100%'
  },
  carouselAssetContainer: {
    position: 'relative'
  },
  carouselImageAsset: {
    width: '100%',
    cursor: 'pointer'
  },
  carouselVideoAsset: {
    width: '100%',
    cursor: 'default'
  }
});

function ReferenceCarousel({ assets }) {
  const [asset, setAsset] = React.useState(assets[0]);
  const [showLargeAsset, setShowLargeAsset] = React.useState(false);

  const assetIndex = React.useRef(0);

  React.useEffect(() => {
    assets.forEach((asset) => {
      if (asset.type === 'image') {
        const downloadedImage = new Image();

        downloadedImage.onload = () => {
          asset.cache = downloadedImage;
        };

        downloadedImage.src = asset.url;
      }
    });

  }, [assets]);

  const nextAsset = () => {
    const value = assetIndex.current;

    if (value === assets.length - 1)
      assetIndex.current = 0;
    else
      assetIndex.current = value + 1;

    setAsset(assets[assetIndex.current]);
  };

  const previousAsset = () => {
    const value = assetIndex.current;

    if (value === 0)
      assetIndex.current = assets.length - 1;
    else
      assetIndex.current = value - 1;

    setAsset(assets[assetIndex.current]);
  };

  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.root}
        container
        spacing={1}
        justify="space-evenly"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <IconButton onClick={previousAsset}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>

        <Grid className={classes.carouselAssetItem} item>
          <Grid
            className={classes.carouselAssetRoot}
            container
            direction="column"
            justify="center"
          >
            <Grid className={classes.carouselAssetContainer} item>
              <AssetDisplay
                asset={asset}
                noTimeout={showLargeAsset}
                DisplayProps={{
                  className: asset.type === 'image' ? classes.carouselImageAsset : classes.carouselVideoAsset
                }}
                onFinished={nextAsset}
                onClick={() => setShowLargeAsset(true)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton onClick={nextAsset}>
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>

      <LargeAssetModal
        open={showLargeAsset}
        asset={asset}
        onClose={() => setShowLargeAsset(false)}
        nextAsset={nextAsset}
        previousAsset={previousAsset}
      />
    </>
  );
}

export default ReferenceCarousel;
