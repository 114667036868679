import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    paragraph: {
      margin: theme.spacing(2),
    },
    divider: {
      width: theme.spacing(25),
      margin: 'auto',
      marginTop: theme.spacing(5),
    },
    constrainedParagraph: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: theme.spacing(80),
    },
    buttonLinkTag: {
      textDecoration: 'none',
    },
    linkButton: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      background: '#fff',
      boxShadow: '0 0 5px #ccc',
      borderRadius: 0,
    },
  };
});

function CompanyInformation() {
  const classes = useStyles();

  return (
    <Section reverse>
      <Typography variant="h5" align="center">The company</Typography>
      <Typography className={classes.paragraph} variant="body1" align="center">
        Sorvimo Optimointipalvelut Oy is a Finnish software company specializing in digital solutions for structural engineering. Sorvimo
        was founded in 2016 to enhance engineering processes in structural design using mathematical optimization and easy-to-use software
        tools.
      </Typography>

      <Typography className={classes.paragraph} variant="body1" align="center">
        Over the years, Sorvimo has broaden its expertise to modern web applications to be able to serve customers in the most effective way.
        Continuous development of the company and the personnel is vital for our success in the future.
      </Typography>

      <Typography className={classes.paragraph} variant="body1" align="center">
        Our office is located in the heart of the Tampere University Hervanta Campus. This provides a close relationship with the academic community
        and utilization of the latest research results.
      </Typography>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <Typography className={classes.paragraph} variant="body1" align="center">
          Read more about Sorvimo's history <RouterLink to="/history">in Finnish</RouterLink>.
      </Typography>
    </Section>
  );
}

export default CompanyInformation;
