import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    stepRoot: {
      marginTop: ({ index }) => theme.spacing(3 + (index > 0 ? 3 : 0)),
    },
    shortText: {
      textTransform: 'none',
    },
    stepIcon: {
      height: '7.5em',
    },
    divider: {
      width: theme.spacing(25),
    },
    buttonLinkTag: {
      textDecoration: 'none',
    },
    linkButton: {
      marginTop: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      background: '#fff',
      boxShadow: '0 0 5px #ccc',
      borderRadius: 0,
    },
  };
});

const steps = [
  {
    text: 'Understanding your work and expertise',
    imageUrl: '/images/landing-page/step1-icon.png',
    imageAlt: 'Research'
  },
  {
    text: 'Roadmap to optimize your performance',
    imageUrl: '/images/landing-page/step2-icon.png',
    imageAlt: 'Roadmap'
  },
  {
    text: 'Building the right digital solutions for you',
    imageUrl: '/images/landing-page/step3-icon.png',
    imageAlt: 'Development'
  }
];

function Step({ index, text, imageUrl, imageAlt }) {
  const classes = useStyles({ index });

  return (
    <Grid
      className={classes.stepRoot}
      container
      direction="column"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <img
          className={classes.stepIcon}
          src={imageUrl}
          alt={imageAlt}
        />
      </Grid>

      <Grid item>
        <Typography className={classes.shortText} variant="h4" align="center">
          {index + 1}.
        </Typography>
        <Typography className={classes.shortText} variant="h4" align="center">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

function OurWay() {
  const classes = useStyles();

  return (
    <Section wide>
      <Grid container spacing={2} direction="column" alignItems="center" justify="center">
        <Grid className={classes.divider} item>
          <Divider direction="horizontal" />
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center">
            Our way
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justify="space-between" alignItems="baseline">
            {steps.map((step, index) => {
              return (
                <Grid key={index} item xs={12} lg={3}>
                  <Step index={index} {...step} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <RouterLink to='/references' className={classes.buttonLinkTag}>
            <Button className={classes.linkButton}>
              Read more about our work
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
    </Section>
  );
}

export default OurWay;
