import React from 'react';
import { Grid, Dialog, useMediaQuery, useTheme, Toolbar, IconButton, Zoom, Slide, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useToolsAnalytics } from '../utils/tools-analytics';

const useStyles = makeStyles({
  popoverPaper: {
    width: '90vw',
    height: '90vh'
  },
  backdrop: {
    background: '#000c'
  },
  iframeRoot: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  toolbarRoot: {
    minHeight: 'unset',
    height: '3em',
  },
  iframeContainer: {
    width: '100%',
    height: ({ smallScreen }) => smallScreen ? 'calc(100% - 3em)' : '100%'
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%'
  }
});

const Transition = React.forwardRef((props, ref) => {
  const childProps = { ...props }

  delete childProps.smallScreen;

  if (props.smallScreen)
    return <Slide direction="up" ref={ref} {...childProps} />;
  return <Zoom ref={ref} {...childProps} timeout={500} />;
});

function ExternalToolView({ tool, onClose }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [smallScreenMode, setSmallScreenMode] = React.useState(false);
  const [mountedTool, setMountedTool] = React.useState(null);

  const analytics = useToolsAnalytics();

  React.useEffect(() => {
    if (!mountedTool) {
      setSmallScreenMode(smallScreen);
    }

    if (!mountedTool && tool)
      setMountedTool(tool);

    return () => {
      if (!tool)
        setMountedTool(null);
    }
  }, [mountedTool, smallScreen, tool]);

  const classes = useStyles({ smallScreen });

  return (
    <Dialog
      classes={{
        root: smallScreenMode ? null : classes.popoverRoot,
        paper: smallScreenMode ? null : classes.popoverPaper
      }}
      maxWidth="xl"
      fullWidth
      fullScreen={smallScreenMode}
      open={Boolean(tool)}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}
      TransitionComponent={Transition}
      TransitionProps={{
        smallScreen: smallScreenMode
      }}
    >
        {mountedTool &&
        <Grid
          className={classes.iframeRoot}
          container
          justify="center"
          alignItems="center"
          direction="column"
          wrap="nowrap"
        >
          {smallScreenMode &&
          <Grid item>
              <Toolbar classes={{ root: classes.toolbarRoot }}>
                <IconButton color="inherit" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
          </Grid>}
          <Grid className={classes.iframeContainer} item>
            {analytics.status !== 'ready' ? (
              <Typography variant="body1" align="center">Loading...</Typography>
            ) : (
              <iframe
                className={classes.iframe}
                src={`${mountedTool.url}?u=${analytics.userId}&t=${analytics.termId}`}
                title={mountedTool.title}
              />
            )}
          </Grid>
        </Grid>}
    </Dialog>
  );
}

export default ExternalToolView;
