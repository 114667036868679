import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '../utils/Divider';
import Section from '../utils/Section';

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      width: theme.spacing(25)
    },
    titleText: {
      color: '#fff'
    },
    textContent: {
      fontSize: 20,
      color: '#fff'
    }
  };
});

function AboutUs() {
  const classes = useStyles();

  return (
    <Section
      reverse
      extendedClearance
      background
      backgroundUrl="/images/landing-page/background-1.jpg"
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid className={classes.divider} item>
          <Divider direction="horizontal" reverse />
        </Grid>

        <Grid item>
          <Typography className={classes.titleText} variant="h5" align="center">About us</Typography>
        </Grid>

        <Grid item lg={10}>
          <Typography
            className={classes.textContent}
            variant="body1"
            align="center"
          >
            Sorvimo's strength is in our team. We have an extensive theoretical background in structural engineering and optimization tasks,
            combined with a strong expertise in software development.
          </Typography>
        </Grid>

        <Grid item lg={10}>
          <Typography
            className={classes.textContent}
            variant="body1"
            align="center"
          >
            Our customer-oriented approach ensures a smooth and transparent project execution. We want to work closely with you through each
            step of the way, from understanding your needs to providing support for the developed solution.
          </Typography>
        </Grid>
      </Grid>

    </Section>
  );
}

export default AboutUs;