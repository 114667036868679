import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ContactCard from '../contact/ContactCard';
import { contacts } from '../data/contacts';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import HyphenatedParagraph from '../utils/HyphenatedParagraph';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    header: {
      marginBottom: theme.spacing(3),
    },
    divider: {
      width: theme.spacing(25),
      margin: 'auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  };
});

function PageFi() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5">
        Hei rakennesuunnittelun osaaja! Kiinnostaisiko mitoitustyökalujen kehitys Hervannassa?
      </Typography>

      <HyphenatedParagraph lang="fi">
        Etsimme Sorvimolle vahvistusta, jolla on kokemusta kantavien rakenteiden mitoituksesta ja
        ymmärrystä rakennusalan prosesseista suunnitteluun liittyen. Olemme ohjelmistoyritys,
        joka keskittyy modernien ja helppokäyttöisten mitoitusohjelmien kehittämiseen. Tavoitteenamme
        on tehostaa asiakkaidemme suunnitteluprosesseja optimoimalla suunnittelutyötä työkalujemme avulla.
      </HyphenatedParagraph>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <Typography variant="h6" >
        Tehtävän kuvaus:
      </Typography>

      <HyphenatedParagraph lang="fi">
        Haemme nykyisen tiimimme tueksi vankkaa osaamista rakenteiden mitoittamisesta ja käytännön kokemusta
        rakennesuunnittelun alalta. Tehtäväsi kattaa koko kehityskaaren ohjelmien suunnittelusta ja kehityksestä
        testaukseen ja loppukäyttäjien tukemiseen. Työskentelet tiiviissä yhteistyössä ohjelmistokehittäjiemme
        ja muiden asiantuntijoiden kanssa. Katsomme eduksesi, jos sinulta löytyy erikoisosaamista esimerkiksi
        teräsrakenteisiin tai betonielementteihin liittyen.
      </HyphenatedParagraph>

      <Typography variant="h6" style={{ marginTop: "1.5rem" }}>
        Odotamme sinulta:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            Rakennustekniikan tutkintoa (DI/TkT) tai vastaavaa pätevyyttä
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Vankkaa kokemusta rakenteiden mitoituksesta (teräs, betoni), alan standardeista ja
            mitoitusohjelmien käytöstä
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Ohjelmointiosaamista sekä intoa ja kykyä kehittää sitä edelleen
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Oma-aloitteisuutta ja kykyä työskennellä itsenäisesti sekä toimia osana monialaista tiimiä
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Ratkaisukeskeisyyttä ja kehittymishalukkuutta
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Sujuvaa suomen ja englannin kielen taitoa.
          </Typography>
        </li>
      </ul>

      <Typography variant="h6">
        Tarjoamme:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            Monipuolisia ja haastavia työtehtäviä, joissa on mahdollisuus vaikuttaa
            suoraan tuotteidemme kehitykseen ja näiden kautta rakennusalan mitoituskäytäntöihin
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Tukea antavan ja eteenpäin vievän työympäristön, jossa on tilaa ammatilliselle
            kasvulle ja kehitykselle
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Ketterän ja matalan organisaation, jossa päätökset tapahtuvat nopeasti
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Joustavat työolosuhteet ja kilpailukykyisen kompensaation, joka vastaa panostasi yrityksemme menestymiseen.
          </Typography>
        </li>
      </ul>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <HyphenatedParagraph lang="fi">
        Jos tunnistat itsesi kuvauksesta ja olet valmis ottamaan seuraavan askeleen urallasi, lähetä
        hakemuksesi ja ansioluettelosi
        osoitteeseen <a href="mailto:lassi-pekka.taskinen@sorvimo.com">lassi-pekka.taskinen@sorvimo.com</a> viimeistään
        31.5.2024 mennessä. Käsittelemme hakemuksia saapumisjärjestyksessä ja pidämme
        haastattelut valittujen hakijoiden kanssa mahdollisimman pian.
      </HyphenatedParagraph>
    </>
  );
}

function PageEn() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" align="center">
        To read this in Finnish <RouterLink to="/rekry">click here</RouterLink>.
      </Typography>

      <HyphenatedParagraph lang="en">
        Sorvimo Optimointipalvelut Oy is an expert company, which combines high-level structural design and software expertise.
        Our strengths are profound theoretical knowledge and systematic teamwork, in which everyone's skills benefit the whole.
        We know our industry thoroughly, and we can offer our customers intelligent total solutions instead of just software. As
        an agile company with a startup background, we offer flexible working conditions for every employee. 
      </HyphenatedParagraph>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <HyphenatedParagraph lang="en">
        We are looking for a specialist in structural analysis or structural engineering for customer projects and development of
        design tools. The more specific content and responsibilities of the job are defined according to the applicant's background
        and development aspirations. 
      </HyphenatedParagraph>

      <HyphenatedParagraph lang="en">
        An applicant with the background of applied mechanics and structural design is best suited for the position. If you are
        already familiar with programming, or are interested in learning it, we will consider it a big advantage. 
      </HyphenatedParagraph>

      <HyphenatedParagraph lang="en">
        In addition to a competitive total salary and flexible working conditions, we offer the opportunity to grow and develop with
        the company and to influence the digitalization of the industry. 
      </HyphenatedParagraph>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <HyphenatedParagraph lang="en">
        Send your application and CV no later than 20.3.2021 to <a href="mailto:lassi-pekka.taskinen@sorvimo.com">lassi-pekka.taskinen@sorvimo.com</a>.
        We will consider all applications received by the deadline. For more information, please send e-mail or call 050 325 7986. 
      </HyphenatedParagraph>
    </>
  );
}

function RecruitmentPage({ lang }) {
  const ceoContact = contacts.find(contact => contact.title.toLowerCase() === 'ceo');

  return (
    <>
      <Section reverse backgroundFill>
        {lang === 'en' &&
        <PageEn />}
        {lang === 'fi' &&
        <PageFi />}
      </Section>

      <Section>
        {ceoContact &&
        <ContactCard
          contact={ceoContact}
          index={0}
          standalone
        />}
      </Section>
    </>
  );
}

export default RecruitmentPage;