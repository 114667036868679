import React from 'react';
import { Box, Typography, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

function RecruitmentBanner() {
  const location = useLocation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (location.pathname.includes("rekry")) {
    return null;
  } else {
    return <Box
      left="0"
      right="0"
      top="64px"
      height="64px"
      bgcolor="#b5b5b5"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      border="3px solid black"
    >
      <Typography variant="body1" align="center">
        {smallScreen ? (
          <>
            WE'RE HIRING!
          </>
        ) : (
          <>
            WE'RE HIRING! Sorvimo is looking
            for an expert in structural engineering.
          </>
        )}
      </Typography>
      <Typography variant="body1" align="center">
        {smallScreen ? (
          <>
            Lean more about this opportunity <Link 
              component={RouterLink}
              to="/rekry"
              variant="body1"
              style={{ color: "blue" }}
            >
              here
            </Link>.
          </>
        ) : (
          <>
            Lean more about this opportunity <Link 
              component={RouterLink}
              to="/rekry"
              variant="body1"
              style={{ color: "blue" }}
            >
              here
            </Link> (in Finnish).
          </>
        )}
      </Typography>
    </Box>
  }
}

export default RecruitmentBanner;
