import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { hyphenateSync as hyphenateSyncFi } from 'hyphen/fi';
import { hyphenateSync as hyphenateSyncEn } from 'hyphen/en';

const useStyles = makeStyles((theme) => {
  return {
    paragraph: {
      hyphens: 'auto',
      margin: theme.spacing(1),
    },
  };
});

function HyphenatedParagraph({ lang, children }) {
  const classes = useStyles();

  const mapText = (text) => {
    if (lang === 'fi')
      return hyphenateSyncFi(text);
    if (lang === 'en')
      return hyphenateSyncEn(text);
  };

  const mapChildren = (children) => {
    if (Array.isArray(children)) {
      return children.map(mapChildren);
    } else if (typeof children === 'string') {
      return mapText(children);
    } else {
      return children;
    }
  };

  return (
    <Typography className={classes.paragraph} variant="body1" align="justify">
      {mapChildren(children)}
    </Typography>
  );
}

export default HyphenatedParagraph;
