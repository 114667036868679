import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import { Link as RouterLink } from 'react-router-dom';
import { recruitingFi, recruitingEn } from '../data/contacts';

const useStyles = makeStyles((theme) => {
  return {
    paragraph: {
      margin: theme.spacing(2),
    },
    divider: {
      width: theme.spacing(25),
      margin: 'auto',
      marginTop: theme.spacing(5),
    },
    constrainedParagraph: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      maxWidth: theme.spacing(80),
    },
    buttonLinkTag: {
      textDecoration: 'none',
    },
    linkButton: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      background: '#fff',
      boxShadow: '0 0 5px #ccc',
      borderRadius: 0,
    },
  };
});

function RecruitingSection() {
  const classes = useStyles();

  const recruiting = recruitingFi || recruitingEn;

  return (
    <Section reverse backgroundFill>
      <Typography variant="h5" align="center">The Sorvimo team</Typography>
      <Typography className={classes.paragraph} variant="body1" align="center">
        Sorvimo provides an opportunity to work in a dynamic and growing company as part of a team of dedicated professionals. With us you get to
        contribute to challenging and interesting projects.
      </Typography>

      <Typography className={classes.paragraph} variant="body1" align="center">
        We have an open atmosphere that promotes thinking outside the box. Only through new ideas can we truly improve our products and services.
      </Typography>

      <Typography className={classes.paragraph} variant="body1" align="center">
        We value continuous learning and support the development of our team. We want to see our employees improving themselves and tackling new
        areas of expertise.
      </Typography>

      <div className={classes.divider} >
        <Divider direction="horizontal" />
      </div>

      <Typography className={classes.paragraph} variant="body1" align="center" component="h1">
        Interested in working at Sorvimo? 
      </Typography>

      {recruiting ? (
        <Typography className={classes.paragraph} variant="body1" align="center" component="h2">
          <p>
            Sorvimo is looking for an <b>expert in structural engineering</b>.
            Read more about it <RecruitingLinks fi={recruitingFi} en={recruitingEn} /> or
            contact <a href="mailto:lassi-pekka.taskinen@sorvimo.com">Lassi-Pekka Taskinen</a> for
            more information!
          </p>
        </Typography>
      ) : (
        <Typography className={classes.constrainedParagraph} variant="body1" align="center" component="h2">
          We are always keeping our eyes open for seasoned experts as well as future professionals.
          Please contact <a href="mailto:lassi-pekka.taskinen@sorvimo.com">Lassi-Pekka Taskinen</a> for more
          information!
        </Typography>
      )}
    </Section>
  );
}

export default RecruitingSection;

function RecruitingLinks({ fi, en }) {
  if (fi && en) {
    return <>
      <RouterLink to="/rekry">in Finnish</RouterLink> / <RouterLink to="/rekry-en">in English</RouterLink>
    </>
  } else if (fi) {
    return <RouterLink to="/rekry">in Finnish</RouterLink>
  } else if (en) {
    return <RouterLink to="/rekry-en">in English</RouterLink>
  }
}
