export const tools = [
  {
    id: 'beam',
    image: '/images/tools/beam-calc.png',
    name: 'Beam',
    description: 'Beam calculation tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/beam/',
    active: true,
  },
  {
    id: 'frame',
    image: '/images/tools/frame-calc.png',
    name: 'Frame',
    description: 'Frame calculation tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/frame/',
    active: true,
  },
  {
    id: 'shape',
    image: '/images/tools/shape-calc.png',
    name: 'Shape',
    description: 'Cross section properties calculator',
    url: 'https://sorvimo-learning.herokuapp.com/apps/shape/',
    active: true,
  },
  {
    id: 'truss',
    image: '/images/tools/truss-calc.png',
    name: 'Truss',
    description: 'Truss calculation tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/truss/',
    active: true,
  },
  {
    id: 'sheet',
    image: '/images/tools/sheet-calc.png',
    name: 'Sheet',
    description: 'Sheet calculation tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/sheet/',
    active: true,
  },
  {
    id: 'slab',
    image: '/images/tools/slab-calc.png',
    name: 'Slab',
    description: 'Slab calculation tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/slab/',
    active: true,
  },
  {
    id: 'floordesign',
    group: 'environment',
    image: '/images/tools/floor-design.png',
    name: 'Floor Design',
    description: 'Floor design tool',
    url: 'https://sorvimo-learning.herokuapp.com/apps/floordesign/',
    active: true,
  }
];