import React from 'react';
import { Box, Container, Fade, Grid, Hidden, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as TitleLogo } from '../assets/sorvimo-title-white.svg';

const useStyles = makeStyles((theme) => {
  return {
    landingImage: {
      backgroundColor: '#111',
      backgroundImage: ({ backgroundImageUrl }) => `linear-gradient(#fff0, #000a), url(${backgroundImageUrl})`,
      backgroundSize: 'cover',
      height: "100vh",
    },
    landingImageMobile: {
      backgroundPosition: '58%',
    },
    landingContainer: {
      height: '100%',
    },
    textContainer: {
      height: '100%',
      width: '100%',
    },
    titleLogoContainer: {
      marginBottom: theme.spacing(1),
    },
    titleLogo: {
      display: 'block',
      height: 'auto',

      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.only('sm')]: {
        width: '70vw',
      },
      [theme.breakpoints.only('md')]: {
        width: '50vw',
      },
      [theme.breakpoints.only('lg')]: {
        width: '40vw',
      },
      [theme.breakpoints.only('xl')]: {
        width: '30vw',
      },
    },
    subtitleText: {
      color: '#fff',
      textTransform: 'none',
      fontWeight: 'normal',
      width: '20em',
      marginTop: theme.spacing(3),
      [theme.breakpoints.only('xs')]: {
        marginTop: theme.spacing(1),
      },
    },
    landingText: {
      color: theme.palette.text.secondary,
    },
  }
});

function LandingLogo() {
  const [backgroundImageUrl, setBackgroundImageUrl] = React.useState('/images/landing-page/background-2-blur.jpg');

  React.useEffect(() => {
    const downloadingImage = new Image();
    const imageUrl = '/images/landing-page/background-2.jpg';

    downloadingImage.onload = () => setBackgroundImageUrl(imageUrl);

    downloadingImage.src = imageUrl;

    return () => {
      downloadingImage.onload = undefined;
      downloadingImage.src = '';
    };
  }, []);

  const theme = useTheme();
  const extraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ backgroundImageUrl });

  return (
    <Box
      className={classNames(classes.landingImage, {
        [classes.landingImageMobile]: useMediaQuery(theme.breakpoints.down('md'))
      })}
    >
      <Container maxWidth="xl" className={classes.landingContainer}>
        <Fade in timeout={1000}>
          <Grid
            className={classes.textContainer}
            container
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <TitleLogo className={classes.titleLogo} />
                </Grid>

                <Grid item>
                  <Typography
                    className={classes.subtitleText}
                    variant={extraSmallScreen ? 'h6' : 'h5'}
                    component="h2"
                  >
                    We optimize your performance and help you outperform the competition.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Hidden smDown>
              <Grid item sm={2} lg={5} />
            </Hidden>
          </Grid>
        </Fade>
      </Container>
    </Box>
  );
}

export default LandingLogo;
