import { Fade } from '@material-ui/core';
import React from 'react';
import DisplaySelector from './DisplaySelector';

function AssetDisplay({ asset, noTimeout, onFinished, onClick, DisplayProps={} }) {
  const [assetSelected, setAssetSelected] = React.useState(false);
  const [cachedAsset, setCachedAsset] = React.useState(asset);
  const [showAsset, setShowAsset] = React.useState(true);

  const assetVisible = React.useRef(false);

  React.useEffect(() => {
    // FIXME do not update cached asset if transition is underway

    if (assetVisible.current) {
      setShowAsset(false);
    } else {
      setCachedAsset(asset);
      setShowAsset(true);
    }
  }, [asset]);

  React.useEffect(() => {
    if (asset.type === 'image') {
      const timeout = setTimeout(() => {
        if (!assetSelected && !noTimeout)
          setShowAsset(false);
      }, 7500);

      return () => clearTimeout(timeout);
    }
  });

  const handleTransitionEnter = () => {
    assetVisible.current = true;
  };

  const handleTransitionExit = () => {
    assetVisible.current = false;

    if (asset.url === cachedAsset.url) {
      onFinished();
    }

    if (asset.url !== cachedAsset.url) {
      setCachedAsset(asset);
      setShowAsset(true);
    }
  };

  const handleMouseEnter = () => {
    setAssetSelected(true);
  };

  const handleMouseLeave = () => {
    setAssetSelected(false);
  };

  const Props = {
    ...DisplayProps,
  }

  if (asset.type === 'video') {
    Props.loop = assetSelected;
    Props.onEnded = () => setShowAsset(false);
  }

  return (
    <>
      <Fade
        in={showAsset}
        timeout={500}
        onEntered={handleTransitionEnter}
        onExited={handleTransitionExit}
      >
        <div
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <DisplaySelector
            asset={cachedAsset}
            selected={assetSelected}
            ComponentProps={{ ...Props }}
          />
        </div>
      </Fade>
    </>
  );
}

export default AssetDisplay;
