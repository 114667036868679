import React from 'react';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme, Zoom } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f2f2f2',
    backgroundImage: ({ tool }) => (tool.active ?
      `linear-gradient(#fff0, #000a), url('${tool.image}')` :
      ' linear-gradient(#ccc, #000)'),
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '2px 3px 3px black',
    width: '100%',
    height: '320px',
    padding: '3em',
    transition: 'background-size 0.5s ease',
    '&:hover': {
      backgroundSize: ({ tool }) => tool.active ? '120% 120%' : null,
    }
  },
  activeTool: {
    cursor: 'pointer'
  },
  disabledTool: {
    color: theme.palette.text.disabled
  },
  container: {
    height: '100%'
  },
  toolName: {
    textDecoration: ({ tool, showDescription }) => showDescription && tool.active ? 'underline' : null 
  }
}));

function ToolCard({ tool, onOpen }) {
  const [showDescription, setShowDescription] = React.useState(!tool.active);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ tool, showDescription });
  const openTool = tool.active ? () => { 
    onOpen(tool); 
  } : null;
  
  return (
    <Box
      className={classNames(classes.root, tool.active ? classes.activeTool : classes.disabledTool)}
      onMouseEnter={tool.active ? () => setShowDescription(true) : null}
      onMouseLeave={tool.active ? () => setShowDescription(false) : null}
      onClick={openTool}
    >
      <Grid
        className={classes.container}
        container
        direction="column"
        wrap="nowrap"
        justify="space-between"
        alignItems="stretch"
      >
        <Grid item />

        <Grid item>
          {!tool.active &&
          <Typography variant="h6">
            Coming next
          </Typography>}

          <Zoom in={smallScreen || showDescription}>
            <Typography variant="body2">
              {tool.description}
            </Typography>
          </Zoom>

          <Typography className={classes.toolName} variant="h4">
            {tool.name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ToolCard;
