import React from 'react';
import { withStyles } from '@material-ui/core';

function debounce(fn, timeout) {
  let timer;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn()
    }, timeout)
  };
}

const styles = {
  root: {
    display: 'block',
    margin: 'auto'
  }
};

function DividerElements({ elementCount, startX, stepX, reverse }) {
  const initialTransform = [4.7946, -1.9863];
  const stepTransform = [1.0375, -2.5049];

  const elements = []
  let x = startX;
  let transform = [...initialTransform];

  for (let i = 0; i < Math.floor(elementCount); i++) {
    elements.push(
      <rect
        key={i}
        x={String(x)}
        y="-1.638"
        transform={`matrix(0.7071 0.7071 -0.7071 0.7071 ${transform[0]} ${transform[1]})`}
        width="0.696"
        height="12.865"
        fill={reverse ? '#fff' : '#000'}
      />
    )

    x += stepX;
    transform = [
      transform[0] + stepTransform[0],
      transform[1] + stepTransform[1]
    ];
  }

  return elements;
}

function mapDirectionToDimension(direction, element) {
  switch (direction) {
    case 'vertical':
      return element.current?.clientHeight ?? 0;

    case 'horizontal':
    default:
      return element.current?.clientWidth ?? 0;
  }
}

function Divider({ classes, direction = 'horizontal', height = '100%', reverse }) {
  const [svgDimension, setSvgDimension] = React.useState(null);

  const rootRef = React.useRef(null);

  if (!['horizontal', 'vertical'].includes(direction)) {
    console.warn(`Unexpected value "${direction}" for prop "direction"!`);
    direction = 'horizontal';
  }

  function handleResize() {
    if (rootRef.current) {
      const currentDimension = mapDirectionToDimension(direction, rootRef);
      setSvgDimension(currentDimension);
    }
  }

  React.useEffect(handleResize, [rootRef])

  React.useEffect(() => {
    if (direction === 'horizontal') {
      const handler = debounce(handleResize, 20);

      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    }
  })

  const initialX = 4.447;
  const stepX = 3.542;
  const numberOfElements = svgDimension ? (svgDimension - 2*initialX) / stepX : 0;

  return (
    <svg
      className={classes.root}
      x="0px"
      y="0px"
      width={direction === 'horizontal' ? '100%' : '9.589px'}
      height={direction === 'horizontal' ? '9.589px' : height}
      viewBox={direction === 'horizontal' ? `0 0 ${svgDimension ?? 0} 9.589` : `0 0 9.589 ${svgDimension ?? 0}`}
      ref={rootRef}
    >
      <g transform={direction === 'vertical' ? 'rotate(90 4.447 5)' : null}>
        <DividerElements
          elementCount={numberOfElements}
          startX={initialX}
          stepX={stepX}
          reverse={reverse}
        />
      </g>
    </svg>
  );
}

export default withStyles(styles)(Divider);
