import React from 'react';
import { Avatar, Grid, Grow, Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    contactCard: {
      height: theme.spacing(15),
      margin: ({ standalone }) => standalone ? 'auto' : 'unset',
    },
    contactCardRoot: {
      height: '100%',
    },
    contactImage: {
      width: 75,
      height: 75,
    },
  };
});

function ContactCard({ contact, index, standalone }) {
  const [showCard, setShowCard] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCard(true);
    }, index * 120);

    return () => clearTimeout(timeout);
  }, [index]);

  const classes = useStyles({ standalone });

  return (
    <Grow in={showCard} timeout={500}>
      <Grid
        item
        xs={12}
        sm={6}
        className={classes.contactCard}
      >
        <Grid
          container
          className={classes.contactCardRoot}
          spacing={2}
          justify={standalone ? "center" : undefined}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Avatar
              className={classes.contactImage}
              src={contact.imageUrl}
              alt={contact.name}
            />
          </Grid>

          <Grid item>
            {contact.title &&
            <Typography variant="body2">{contact.title}</Typography>}
            {contact.areaOfExpertise &&
            <Typography variant="body2">{contact.areaOfExpertise}</Typography>}

            <Typography variant="h6">{contact.name}</Typography>

            {contact.email &&
            <Typography variant="body2">{contact.email}</Typography>}

            {contact.mobile &&
            <Typography variant="body2">{contact.mobile}</Typography>}

            {contact.linkedIn &&
            <Link
              href={contact.linkedIn}
              variant="body2"
              color="textPrimary"
            >
              LinkedIn
            </Link>}
          </Grid>
        </Grid>
      </Grid>
    </Grow>
  );
}

export default ContactCard;
