import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Layout from './Layout';
import { theme } from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout />
    </ThemeProvider>
  );
}

export default App;
