import React from 'react';
import Section from '../utils/Section';
import { Typography } from '@material-ui/core';

function Error404Page({ url }) {
  return (
    <Section>
      <Typography variant='h4'>
        404
      </Typography>

      <Typography variant='body1'>
        {`The page ${url} was not found.`}
      </Typography>
    </Section>
  );
}

export default Error404Page;
