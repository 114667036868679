import React from 'react';
import { Grid, Typography, withStyles, Link } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Section from '../utils/Section';
import { company } from '../data/contacts';

const styles = (theme) => {
  return {
    linkedInIcon: {
      color: theme.palette.text.primary
    }
  }
};

function ContactInformationSection({ classes }) {
  return (
    <Section background>
      <Grid container justify="flex-end">

      <Grid item xs={12} lg={4}>
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">
                Postal and street address
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle1">
                {company.name}
              </Typography>
              <Typography variant="body2">
                {company.address}
              </Typography>
              <Typography variant="body2">
                {company.postalCode} {company.city}
              </Typography>
              <Typography variant="body2">
                {company.country}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Business ID: {company.businessId}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Email: {company.email}
              </Typography>
            </Grid>

            <Grid item>
              <Link href={company.linkedInUrl}>
                <LinkedInIcon className={classes.linkedInIcon} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withStyles(styles)(ContactInformationSection);
