import React from 'react';
import LandingLogo from './LandingLogo';
import Solutions from './Solutions';
import OurWay from './OurWay';
import AboutUs from './AboutUs';
import Contact from './Contact';

function LandingPage() {
  return (
    <>
      <LandingLogo />
      <Solutions />
      <OurWay />
      <AboutUs />
      <Contact />
    </>
  );
}

export default LandingPage;
