import React from 'react';
import { Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import Divider from '../utils/Divider';
import Section from '../utils/Section';
import ReferenceCarousel from './ReferenceCarousel';
import MobileReferenceCarousel from './MobileReferenceCarousel';

const useStyles = makeStyles({
  quoteRoot: {
    alignSelf: 'center'
  },
  companyLogoImage: {
    maxHeight: 150,
    maxWidth: 300
  }
});

function ReferenceSection({ reference, index, ...rest }) {
  const classes = useStyles();

  return (
    <Section extraWide reverse={!Boolean(index % 2)} {...rest}>
      <Grid
        container
        spacing={2}
        direction={index % 2 ? 'row' : 'row-reverse'}
        justify="center"
      >
        <Hidden mdDown>
          <Grid item lg={7}>
            <ReferenceCarousel assets={reference.assets} />
          </Grid>

          <Grid item lg={1}>
            <Divider direction="vertical" height="100%" />
          </Grid>
        </Hidden>

        <Grid
          className={classes.quoteRoot}
          item
          lg={4}
          xs={12}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img
                className={classes.companyLogoImage}
                src={reference.companyLogo}
                alt={reference.company}
              />
            </Grid>

            <Grid item>
              <Typography variant="body1" align="center">
                {`"${reference.quote}"`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" align="center">
                <b>{reference.person}</b>
              </Typography>
              <Typography variant="body2" align="center">
                {reference.title}
              </Typography>
              <Typography variant="body2" align="center">
                {reference.company}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Hidden lgUp>
          <Grid item xs={12}>
            <MobileReferenceCarousel assets={reference.assets} />
          </Grid>
        </Hidden>
      </Grid>
    </Section>
  );
}

export default ReferenceSection;
