import React from 'react';
import { Backdrop, Fade, Grid, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RawDisplay from './RawDisplay';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageContainer: {
      pointerEvents: 'none'
    },
    largeImage: {
      maxWidth: '80%',
      maxHeight: '80%',
      pointerEvents: 'unset'
    },
    imageText: {
      marginTop: theme.spacing(2),
      color: theme.palette.secondary.light
    }
  };
});

const LargeAssetModal = ({ open, asset, onClose, nextAsset, previousAsset }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.root}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#000c' }
      }}
      onClose={onClose}
    >
      <>
        <Grid item>
          <IconButton onClick={previousAsset}>
            <NavigateBeforeIcon color="secondary" />
          </IconButton>
        </Grid>

        <Grid className={classes.imageContainer} item xs={9}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Fade in={open}>
              <RawDisplay
                asset={asset}
                ComponentProps={{
                  className: classes.largeImage
                }}
              />
            </Fade>
            <Typography className={classes.imageText}>
              {asset.title && asset.description ? `${asset.title} - ${asset.description}` : ''}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton onClick={nextAsset}>
            <NavigateNextIcon color="secondary" />
          </IconButton>
        </Grid>
      </>
    </Modal>
  );
};

export default LargeAssetModal;
