import React from 'react';
import { contacts, experts } from '../data/contacts';
import ContactSection from './ContactSection';
import RecruitingSection from './RecrutingSection';

function Contact() {
  return (
    <>
      <RecruitingSection />
      <ContactSection contacts={contacts} groupName="Contacts" />
      <ContactSection contacts={experts} groupName="Technical experts" reverse />
    </>
  );
}

export default Contact;
