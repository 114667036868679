import React from 'react';
import { Typography, Grid, Container, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '../utils/Divider';
import FooterLinks from './FooterLinks';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    containerRoot: {
      height: '100%',
    },
    privacyPolicyLink: {
      marginRight: theme.spacing(1),
    },
  }
});

function Footer() {
  const classes = useStyles();

  return (
    <footer
      className={classes.root}
    >
      <Container maxWidth="lg">
        <Grid
          className={classes.containerRoot}
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <FooterLinks />
          </Grid>

          <Grid item>
            <Divider />

            <Grid container justify="space-between" alignItems="baseline">
              <Grid item>
                <Typography variant="subtitle1">
                  <small>{`© ${new Date().getFullYear()} Sorvimo Optimointipalvelut Oy`}</small>
                </Typography>
              </Grid>

              <Grid className={classes.privacyPolicyLink} item>
                <Link
                  component={RouterLink}
                  to="/privacy"
                  color="inherit"
                  variant="body2"
                >
                  <small>Privacy policy</small>
                </Link>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
