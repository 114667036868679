import React from 'react';
import { Button, Grid, Paper, TextField, useMediaQuery, useTheme, withStyles } from '@material-ui/core';

const styles = (theme) => {
  return {
    contactFormPaper: {
      padding: theme.spacing(8),
      background: theme.palette.focus.main,
      color: theme.palette.text.secondary
    },
    contactFormPaperSmall: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(2),
      background: theme.palette.focus.main,
      color: theme.palette.text.secondary
    },
    contactFormRoot: {
      background: theme.palette.secondary.dark
    },
    contactFormInput: {
      color: 'white'
    },
    sendButton: {
      borderRadius: 0
    }
  }
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function ContactUsForm({ classes }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailOk, setEmailOk] = React.useState(true);
  const [message, setMessage] = React.useState('');

  const [working, setWorking] = React.useState(false);
  const [buttonText, setButtonText] = React.useState('Send');

  const handleEmailUpdate = (event) => {
    setEmail(event.target.value);
    setEmailOk(validateEmail(event.target.value));
  };

  const sendContactUsForm = () => {
    if (!working) {
      setWorking(true);

      fetch('/api/contact_us', {
        method: 'POST',
        mode: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          content: message
        })
      })
        .then((response) => {
          setWorking(false);

          if (response.ok) {
            setName('');
            setEmail('');
            setEmailOk(true);
            setMessage('');
            setButtonText('Send');
          } else {
            setButtonText('Error, please try again');
          }
        })
        .catch((reason) => {
          console.warn('API call rejected:', reason);

          setButtonText('Error, please try again');
          setWorking(false);
        });
    }
  };

  const formIsValid = name !== '' && validateEmail(email) && message !== '';

  return (
    <Paper className={smallScreen ? classes.contactFormPaperSmall : classes.contactFormPaper}
           square>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            className={classes.contactFormRoot}
            label="Name"
            variant="filled"
            fullWidth
            value={name}
            onChange={event => setName(event.target.value)}
            InputProps={{
              className: classes.contactFormInput
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            className={classes.contactFormRoot}
            label="Email"
            variant="filled"
            fullWidth
            value={email}
            onChange={handleEmailUpdate}
            error={!emailOk}
            helperText={!emailOk ? 'Invalid email address' : null}
            InputProps={{
              className: classes.contactFormInput
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            className={classes.contactFormRoot}
            label="Type your message here"
            variant="filled"
            multiline
            rows={10}
            fullWidth
            value={message}
            onChange={event => setMessage(event.target.value)}
            InputProps={{
              className: classes.contactFormInput
            }}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.sendButton}
            variant="contained"
            fullWidth
            size="large"
            disabled={!formIsValid || working}
            onClick={sendContactUsForm}
          >
            {working ? 'Sending...' : buttonText}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(ContactUsForm);
