import React from 'react';
import { AppBar, Toolbar, Grid, Hidden, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/sorvimo-logo1.svg';
import NarrowScreenBar from './NarrowScreenBar';
import WideScreenBar from './WideScreenBar';
import RecruitmentBanner from './RecruitmentBanner';
import { recruitingEn, recruitingFi } from '../data/contacts';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxHeight: '100%',
      paddingTop: 3
    },
    logo: {
      height: theme.spacing(5),
      width: 'auto'
    }
  }
});

function MenuBar(_, ref) {
  const classes = useStyles();

  const recruiting = recruitingFi || recruitingEn;

  return (
    <>
      <AppBar ref={ref} position="sticky">
        <Toolbar>
          <Grid
            className={classes.root}
            container
            alignItems="center"
            spacing={4}
            justify="space-between"
          >
            <Grid item>
              <RouterLink to="/">
                <Logo className={classes.logo} />
              </RouterLink>
            </Grid>

            <Hidden mdDown>
              <WideScreenBar />
            </Hidden>

            <Hidden lgUp>
              <NarrowScreenBar />
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      {recruiting && <RecruitmentBanner />}
    </>
  );
}

export default React.forwardRef(MenuBar);
