import React from 'react';
import { Grid, IconButton, makeStyles, Menu, MenuItem, Typography  } from '@material-ui/core';
import { ReactComponent as MobileMenuLogo } from '../assets/sorvimo-mobile-menu.svg';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  mobileMenuRoot: {
    flexGrow: 1 
  }
});

function NarrowScreenBar() {
  const [dropdownAnchor, setDropdownAnchor] = React.useState(null);

  const closeMenu = () => {
    setDropdownAnchor(null);
  }

  const classes = useStyles();

  return (
    <Grid className={classes.mobileMenuRoot} item>
      <Grid container justify="flex-end" >
        <IconButton onClick={(event) => setDropdownAnchor(event.currentTarget)}>
          <MobileMenuLogo />
        </IconButton>
      </Grid>
      <Menu
        anchorEl={dropdownAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(dropdownAnchor)}
        onClose={closeMenu}
      >
        <MenuItem component={RouterLink} to="/" onClick={closeMenu}>
          <Typography variant="overline">
            Home
          </Typography>
        </MenuItem>

        <MenuItem component={RouterLink} to="/tools" onClick={closeMenu}>
          <Typography variant="overline">
            Sorvimo tools
          </Typography>
        </MenuItem>

        <MenuItem component={RouterLink} to="/references" onClick={closeMenu}>
          <Typography variant="overline">
            References
          </Typography>
        </MenuItem>

        <MenuItem component={RouterLink} to="/team" onClick={closeMenu}>
          <Typography variant="overline">
            Team
          </Typography>
        </MenuItem>

        <MenuItem component={RouterLink} to="/company" onClick={closeMenu}>
          <Typography variant="overline">
            Company
          </Typography>
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default NarrowScreenBar;
