import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Section from '../utils/Section';
import Divider from '../utils/Divider';

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      width: theme.spacing(25)
    },
    mainTitle: {
      textTransform: 'none'
    }
  }
});

function Solutions() {
  const classes = useStyles();

  return (
    <Section reverse backgroundFill extendedClearance>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid className={classes.divider} item xs={12}>
          <Divider direction="horizontal" />
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center" component="h1">
            Calculation tools for your needs
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.mainTitle} variant="h4" align="center" component="h2">
            Our mission is to help engineering companies and professionals work in the most optimal way.
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Typography variant="body1" align="center" component="h3">
            We have combined our strong expertise in structural engineering and software development to
            provide the leading digital solutions for engineering.
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
}

export default Solutions;
