import { makeStyles, Paper, Typography, Link, Button, Grid } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSsrLocalStorage } from '../utils/ssrLocalStorage';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'fixed',
      width: 400,
      height: 150,
      bottom: 20,
      right: 20,
      padding: theme.spacing(3),
      borderRadius: 0,

      [theme.breakpoints.down('sm')]: {
        height: 160,
        width: 'unset',
        bottom: 0,
        left: 0,
        right: 0,
      }
    },
    closeButton: {
      borderRadius: 0,
    },
  };
})

function PrivacyDisclaimer() {
  const [showDisclaimer, setShowDisclaimer] = useSsrLocalStorage('disclaimer', 'true');

  const classes = useStyles();

  if (showDisclaimer === 'false')
    return null;

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="body2" align="center">
            {`This site uses Web Storage and Cookies. `}
            <Link
              component={RouterLink}
              to="/privacy"
              color="inherit"
              variant="body2"
            >
              See privacy policy for more information.
            </Link>
          </Typography>
        </Grid>

        <Grid item>
          <Button
            className={classes.closeButton}
            variant="contained"
            fullWidth
            size="large"
            onClick={() => setShowDisclaimer('false')}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PrivacyDisclaimer;
