import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import MenuBar from './menu-bar/MenuBar';
import LandingPage from './landing-page/LandingPage';
import Footer from './footer/Footer';
import Tools from './tools/Tools';
import Error404Page from './error-pages/Error404Page';
import Contact from './contact/Contact';
import References from './references/References';
import PrivacyPolicy from './privacy/PrivacyPolicy';
import { Grid, makeStyles, NoSsr } from '@material-ui/core';
import PrivacyDisclaimer from './privacy/PrivacyDisclaimer';
import RecruitmentPage from './recruitment/RecruitmentPage';
import { recruitingFi } from './data/contacts';
import { recruitingEn } from './data/contacts';
import HistoryPage from './history/HistoryPage';
import Company from './company/Company';

const useStyles = makeStyles({
  root: {
    minHeight: ({ appBarHeight }) => `calc(100vh - ${appBarHeight}px)`,
  },
  contents: {
    width: '100%',
    flexGrow: 1,
  },
  footer: {
    width: '100%',
  },
})

function Layout() {
  const appBarRef = React.useRef();
  const [appBarHeight, setAppBarHeight] = React.useState(64);

  React.useEffect(() => {
    const handleResize = () => {
      if (appBarRef.current) {
        setAppBarHeight(appBarRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useLayoutEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, []);

  const location = useLocation();
  const debug = process.env.NODE_ENV === 'development';
  
  // This will trigger Google Analytics tag.
  React.useEffect(() => {
    if (!debug) {
      window.gtag('event', 'page_view', {
        page_title: location.pathname.replace('/', ''),
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
        //send_to: 'G-MYF2474VHK',
      });
    }
  }, [debug, location]);

  const classes = useStyles({ appBarHeight });

  return (
    <>
      <MenuBar ref={appBarRef} />

      <Grid className={classes.root} container direction="column">
        <Grid className={classes.contents} item>
          <Switch>
            <Route path="/" exact>
              <LandingPage />
            </Route>

            <Route path="/tools" exact>
              <Tools />
            </Route>

            <Route path="/references" exact>
              <References />
            </Route>

            <Route path="/company" exact>
              <Company />
            </Route>

            <Route path="/team" exact>
              <Contact />
            </Route>

            <Route path="/privacy" exact>
              <PrivacyPolicy />
            </Route>

            <Route path="/rekry" exact>
              {recruitingFi
                ? <RecruitmentPage lang="fi" />
                : <Redirect to="/" />}
            </Route>

            <Route path="/rekry-en" exact>
              {recruitingEn
                ? <RecruitmentPage lang="en" />
                : <Redirect to="/" />}
            </Route>

            <Route path="/history" exact>
              <HistoryPage />
            </Route>

            <Route>
              <Error404Page url={location.pathname} />
            </Route>
          </Switch>
        </Grid>

        <Grid className={classes.footer} item>
          <Footer />
        </Grid>
      </Grid>

      <NoSsr>
        <PrivacyDisclaimer />
      </NoSsr>
    </>
  );
}

export default Layout;
