import React from 'react';
import { NoSsr, Fade, Grid, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import ToolCard from './ToolCard';
import { tools } from '../data/tools';

const useStyles = makeStyles((theme) => ({
  toolsRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toolCard: {
    width: 300
  },
}));

const ToolGroup = ({ group, openTool }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  return <Grid className={classes.toolsRoot} item>
    <NoSsr>
      <Fade in timeout={500}>
        <Grid
          container
          justify="flex-start"
          spacing={2}
        >
          {tools.map((tool, index) => group === tool.group && <Grid key={index} className={classes.toolCard} item>
            <ToolCard
              tool={tool}
              onOpen={openTool}
            />
          </Grid>)}

          {smallScreen && tools.length % 2 &&
          <Grid className={classes.toolCard} item />}
        </Grid>
      </Fade>
    </NoSsr>
  </Grid>
}

export default ToolGroup;