export const references = [
  {
    person: 'Jyrki Kesti',
    title: 'Technology director',
    company: 'Ruukki Construction Oy',
    quote: "Sorvimo's solid expertise in construction engineering and software development have made it possible to smoothly reform Ruukki's design software. The modern, user-friendly applications have been delivered on time and have been well-received by the designers.",
    companyLogo: '/images/references/ruukki-logo.png',
    assets: [
      {
        type: 'image',
        url: '/images/references/design-tools-portal.png',
        title: "Ruukki Design Tools portal",
        description: "Ruukki's cloud based calculation tools allow engineers to rapidly design Ruukki's load bearing sheets and sandwich panels for their projects."
      },
      {
        type: 'image',
        url: '/images/references/snowcalculator.png',
        title: "Snow Calculator",
        description: "Tool for calculating roof snow loads according to EN1991-1-3."
      },
      {
        type: 'image',
        url: '/images/references/traypan-results.png',
        title: "TrayPan",
        description: "Dimensioning tool for sandwich panels."
      }
    ]
  },
  {
    person: 'Dan Pada',
    title: 'Technology manager',
    company: 'Nordec Oy',
    quote: "The services of Sorvimo Optimointipalvelut Oy have been of great help to Nordec Oy's product and software development. In particular, solid expertise of Sorvimo's staff in demanding FEM structural analyzes has proven to be very useful and has brought new benefits to our products. The challenging tasks have been solved with great professional skill on schedule. Co-operation has been fluent and continues in the same way.",
    companyLogo: '/images/references/nordec-logo.png',
    assets: [
      {
        type: 'image',
        url: '/images/references/easybeam-section.png',
        title: "Workflow improvements",
        description: "Intuitive user interfaces provide instant feedback to the user and simplify the design process."
      },
      {
        type: 'image',
        url: '/images/references/easybeam-results.png',
        title: "Visualizations",
        description: "Providing the relevant information in a clear fashion allows users to rapidly detect and correct problems."
      },
      {
        type: 'video',
        url: '/images/references/nordec-fem.mp4',
        title: null,
        description: null
      }
    ]
  },
  {
    person: 'Marko Saikkonen',
    title: 'CEO',
    company: 'D.O.F Tech Oy',
    quote: "Our company has used the services of Sorvimo Optimointipalvelut Oy in the development of our software products since 2016. Sorvimo has helped us with its special expertise to develop our products into new product areas. Our new cloud service also includes demanding numerical computing (e.g. strength calculations). With Sorvimo we have been able to develop our products to a new technological level. We have been very pleased with Sorvimo's services in projects that require their expertise in engineering, optimization and software development. Our co-operation will continue.",
    companyLogo: '/images/references/laskentapalvelut-logo.png',
    assets: [
      {
        type: 'image',
        url: '/images/references/laskentapalvelut-portal.jpg',
        title: "Calculationtools.com",
        description: "Free calculation tools for architectural and structural engineering."
      },
      {
        type: 'image',
        url: '/images/references/laskentapalvelut-1.jpg',
        title: "Sensor management",
        description: "Combine and view data from multiple sensors."
      },
      {
        type: 'image',
        url: '/images/references/laskentapalvelut-2.jpg',
        title: "Wall selector",
        description: "Find correct wall product based on requirements."
      }
    ]
  }
];
